

export default {
  	name: "ServicosReprovados",
  	props: {
		id: {
			type: String,
			required: true,
		},
		itensReprovados: {
            type: Object,
            required: true,
        }
 	},
}
