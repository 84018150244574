
import { useRouter } from "vue-router";
import { defineComponent, reactive, toRefs, ref, onMounted, watch } from "vue";
import {getNegociacaoList, setOrcamentoNegociacao, setFinalizarNegociacao,} from "@/services/OficinaDigital";
import useEmitter from "@/composables/Emmiter";
import { Modal } from "bootstrap";
import useAlert from "@/composables/Alert.ts";
import MessageModal from "@/components/modals/geral/MessageModal.vue";
import { enviarMensagem } from "@/services/GeralService";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { ContentLoader } from 'vue-content-loader';
import { hideModal } from '@/core/helpers/dom';
import { atualizaOrcamento } from "@/services/OficinaDigital";

export default {
  name: "EdicaoOrcamentosModal",

  components: {
    MessageModal,
    ContentLoader
  },
  
  props: {
    data:{ 
      type: Array,
      required: true,
    },
    servicos: {
      type: Array,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    checklist: {
      type: Object,
      required: true,
    },
    openModal: {
      type: Number,
      required: true,
    },
  },

  emits: ["send"],
  setup(props, { emit }) {
    const { showConfirmAlert, showTimeAlert } = useAlert();
    let animate = ref(true);
    const clicked = ref(false);
    const emitter = useEmitter();
    var itensNegociacao = ref();
    const router = useRouter();
    const modalFiltro: any = ref();
    const modalTelefone = ref(""); //variavel para pegar o telefone do cliente e aparecer no modal
    const data:any = reactive({
              modelo:"",
              marca: "",
              telefone: "",
              mensagem: null,
              cliente: "",
              codConcessionaria: 0,
              codChecklist: 0,
              checklist: 0,
              tipo: 0,
              email:""
            })

      const modalEtapa = ref(0);


      watch(props.data, () => {
     	  console.log("props.dados", props.data) 
      })

    function selecionaNumero(telefone) {
      //caso exista mais de um telefone, escolhe a melhor opção para wpp e sms
      let telefoneSelecionado = "";
     /*  console.log(telefone); */
      if (telefone) {
        let replaceTelefone = telefone.replaceAll(" ", ""); //retira espacos do telefone ex: (31)33333333(31)222222222
        replaceTelefone = replaceTelefone.replaceAll(")", ""); //retira parenteses ex: (3133333333(3122222222
        let splitTelefone = replaceTelefone.split("("); //Cria array separando pelo parenteses ex: ["","3133333333","3122222222"]
        let arrayTelefones = splitTelefone.filter((a) => a); //Cria filtro para retirar null e vazio ex: ["3133333333","3122222222"
        if (arrayTelefones.length > 1) {
          //Verifica se existe mais de um numero de telefone
    /*       console.log("arrayTelefones", arrayTelefones); */
          arrayTelefones.forEach((value, index) => {
            //percorre o array de telefones
            if (value.length == 11) {
              //verifica se tem telefone com 11 digitos (se tem o nono digito que provavelmente é um celular)
              telefoneSelecionado = value; //seleciona o telefone que provavelmente é um celular (contem wpp).
            }
          });
        } else {
          telefoneSelecionado = arrayTelefones[0]; //se for apenas um telefone, seleciona
        }
      } else {
        telefoneSelecionado = "";
      }
      modalTelefone.value = telefoneSelecionado;
    }

      async function atualiza(postData) {

     
         var response = await atualizaOrcamento(postData);
        /* console.log("response mudança editar", response) */

        return response;
    }
	
    watch(() => props.openModal,() => {
        openModal();
        selecionaNumero(props.checklist.telefone);
       /*  console.log("props.checklist",props.checklist) */
      }
    );



    async function sms(tipo = 0) {
      animate.value = true
      if (!clicked.value) {
        clicked.value = true;
        const dados = {
          marca: props.marca,
          telefone: modalTelefone.value,
          mensagem: tipo == 2 || tipo == 3 ? null : props.data.mensagem,
          cliente: props.cliente,
          codConcessionaria: props.checklist.codConcessionaria,
          codChecklist: props.checklist.codChecklist,
          checklist: props.checklist.checklist,
          tipo: tipo,
        };
       /*  console.log(dados); */
        const response = await enviarMensagem(dados)
          .then((value) => {
            tipo != 1 ? window.open(value, "_blank") : alert(value, true);
          })
          .catch((error) => {
            alert(error.response.data.telefone, false);
          });

        clicked.value = false;
        animate.value = false
        const elementModal = document.getElementById("kt_modal_3_1");
				hideModal(elementModal);
      }
    }

    function alert(texto, verificador) {
      const icon = verificador ? "success" : "error";
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: icon,
        background: "$cor_indicadores_1",
        title: texto,
      });
    }

	  function procuraAcesso (item){
      /* 	console.log("procuraAcesso", item) */
        if  (item.situacao == true){
      /* 		console.log("procuraAcesso", item) */

        return "Aprovado";

        } else {
        return "Reprovado";
        };  
   	};

    function alteraSituacao(item) {

      if (item.situacao) {
        Swal.fire({
          title: "Confirmação de alteração",
          text: "Essa alteração não poderá ser desfeita!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Aprovar serviço",
          cancelButtonText: "cancelar",
          buttonsStyling: false,
          customClass: {
            confirmButton:
              "btn btn-sm btn-outline background_btn_2_opct border_btn_1 cor_btn_1",
            cancelButton:
              "btn btn-sm btn-outline background_btn_2_opct border_btn_1 cor_btn_1",
          },
          reverseButtons: true,
        }).then((result) => {
          if (result.isConfirmed) {
              props.checklist.servicos?.forEach((value, index) => {
            let aux = value.situacao == "true" ? 1 : 0; //mapeia situacao para binario
            if (aux != value.situacao) {
            //verifico se a situacao anterior é diferente da atual
                let postData = {
                    codOrcamento: value.codOrcamento,
                    codConcessionaria: String(props.checklist.codConcessionaria),
                    checklist: String(props.checklist.checklist),
                    codCheckList: String(props.checklist.codChecklist),
                    aprovacao: value.situacao == true ? 1 : 2, //2 caso seja aprovado e 1 caso seja reprovado (no banco o 0 é para nao selecionado)
                };
              /*  console.log("postdata",postData) */
                atualiza(postData);

                /* console.log("postdata",postData) */
                //fechar o modal e recarregar a página
            }
             const elementModal = document.getElementById("id");

            
        });

        
          } else  {
            item.situacao = false;
          }
        });
      }
      // console.log(save);
    }

    async function atualizaNegociacao(postData) {
      animate.value = true;
      var response = await setOrcamentoNegociacao(postData);
     /*  console.log("response negociação", response) */
      /* console.log("Atualiza negociacao", response); */
      animate.value = false
      return response;
    }

    function confirmacaoFinalizarNegociacao(codConcessionaria, checklist, codCheckList) {
      Swal.fire({
        title: "Confirmação de alteração",
        text: " Ao finalizar a negociação, não poderá abrir negociação novamente!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Finalizar negociação",
        buttonsStyling: false,
        customClass: {
          confirmButton:
            "btn btn-sm btn-outline background_btn_2_opct border_btn_1 cor_btn_1",
          cancelButton:
            "btn btn-sm btn-outline background_btn_2_opct border_btn_1 cor_btn_1",
        },
        cancelButtonText: "cancelar",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          props.checklist.servicos?.forEach((value, index) => {           
            let aux = value.situacao == "true" ? 1 : 0;
            const aux2 = value.situacao == "false"//mapeia situacao para binario
            if (aux != value.situacao) {
            //verifico se a situacao anterior é diferente da atual
                let postData = {
                    codOrcamento:  String(props.checklist.codConcessionaria),
                    codConcessionaria: String(props.checklist.codConcessionaria),
                    checklist: String(props.checklist.checklist),
                    codCheckList: String(props.checklist.codChecklist),
                    aprovacao: value.situacao == true ? 1 : 2, //2 caso seja aprovado e 1 caso seja reprovado (no banco o 0 é para nao selecionado)
                };
                atualiza(postData); 
                const elementModal = document.getElementById("id");
                hideModal(elementModal);
                modalFiltro.value.hide();             
                finalizarNegociacao(codConcessionaria, checklist, codCheckList)
                //fechar o modal e recarregar a página
                emitter.emit("atualiza-gerenciar");
            } else if (aux2 == value.situacao) {
            //verifico se a situacao anterior é diferente da atual
                let postData = {
                    codOrcamento:  String(props.checklist.codConcessionaria),
                    codConcessionaria: String(props.checklist.codConcessionaria),
                    checklist: String(props.checklist.checklist),
                    codCheckList: String(props.checklist.codChecklist),
                    aprovacao: value.situacao == true ? 1 : 2, //2 caso seja aprovado e 1 caso seja reprovado (no banco o 0 é para nao selecionado)
                };
                atualiza(postData);
                const elementModal = document.getElementById("id");
                hideModal(elementModal);
                modalFiltro.value.hide();             
                finalizarNegociacao(codConcessionaria, checklist, codCheckList)
                //fechar o modal e recarregar a página
                emitter.emit("atualiza-gerenciar2");
            }                      
        });
        
        } else if (result.dismiss === Swal.DismissReason.cancel) {
        }
      });
    }

    async function finalizarNegociacao(codConcessionaria, checklist, codCheckList) {
      var response = await setFinalizarNegociacao(
        codConcessionaria,
        checklist,
        codCheckList
      );
     /*  console.log("Finaliza negociação: ", response);  */
      emit("send");
    }
    function goToParams(routeName, codCheckList, checklist) {
      router.push({
        name: routeName,
        params: {
          codCheckList,
          checklist,
          codConcessionaria: props.checklist.codConcessionaria,
        },
      });
    }

    function openModal(staticModal = false) {
      animate.value = true;
      itensNegociacao.value = props.checklist.servicos;
      const refModal = document.getElementById(`${props.id}`);
      if (refModal) {
        document.getElementById("layout_content")?.appendChild(refModal);
      }
      animate.value = false;
      if (staticModal) {
        modalFiltro.value = new Modal(refModal, {
          keyboard: false,
          backdrop: "static",
        });
         animate.value = false;
      } else {
        modalFiltro.value = new Modal(refModal);
      }

      modalFiltro.value.show();
      animate.value = false;
    }

    function precificar() {
      animate.value = true;
      modalFiltro.value.hide();
      goToParams(
        "oficina-digital-precificar",
        props.checklist.codChecklist,
        props.checklist.checklist
      );
      animate.value = false;
    }
    return {
      itensNegociacao,
      alteraSituacao,
      atualizaNegociacao,
      confirmacaoFinalizarNegociacao,
      modalTelefone,
      openModal,
      goToParams,
      sms,
      precificar,
      clicked,
	    procuraAcesso,
      animate,
      atualiza

	  
    };
  },
};
