
import { defineComponent, reactive, toRefs, ref, onMounted, watch } from "vue";
import { atualizaOrcamento } from "@/services/OficinaDigital";
import useAlert from "@/composables/Alert.ts";
import { hideModal } from '@/core/helpers/dom';
import useEmitter from '@/composables/Emmiter';
import store from "@/store/index";


export default {
  name: "EditarOrcamentoModal",
  props: {
    servicos: {
      type: Array,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    orcamentoSelected: {
      type: Object,
      required: false,
    },
    hiddenButton: {
		type: Boolean,
		default: false,
	},
  },

  setup(props) {
    let loadingButton = ref(false);
    const { showConfirmAlert, showTimeAlert } = useAlert();
    const emitter = useEmitter();
    const dominio_orcamento = store.getters.layoutConfig("distribuidor.dominio_orcamento");

    function fecharModal() {
        loadingButton.value = false;
        showTimeAlert("Os orçamentos foram atualizados");
        const elementModal = document.getElementById("gerenciar-editar-orcamento");
        hideModal(elementModal);
        window.open(`http://`+(dominio_orcamento=='localhost'?dominio_orcamento+'/acampa-orcamento':dominio_orcamento)+`/imprimir.php?codConcessionaria=${props.orcamentoSelected.codConcessionaria}&codCheckList=${props.orcamentoSelected.codChecklist}&checklist=${props.orcamentoSelected.checklist}&enviaorcamento=2`);
    }

    async function save() {
        let cont = 0;
        props.servicos?.forEach((value, index) => {
            let aux = value.situacao == "aprovado" ? 1 : 0; //mapeia situacao para binario
            if (aux != value.situacaoOnOff) {
            //verifico se a situacao anterior é diferente da atual
                let postData = {
                    codOrcamento: value.codOrcamento,
                    codConcessionaria: String(props.orcamentoSelected.codConcessionaria),
                    checklist: String(props.orcamentoSelected.checklist),
                    codCheckList: String(props.orcamentoSelected.codChecklist),
                    aprovacao: value.situacaoOnOff == true ? 1 : 2, //2 caso seja aprovado e 1 caso seja reprovado (no banco o 0 é para nao selecionado)
                };
                
                atualiza(postData).then(v=> {
                    cont++;
                    if(cont == props.servicos.length){
                      fecharModal();
                    }
                });
                //fechar o modal e recarregar a página
                emitter.emit("atualiza-gerenciar");
            }else{
                cont++;
                if(cont == props.servicos.length){
                    fecharModal();                      
                }
            }
        });
      
    }

    async function atualiza(postData) {
        loadingButton.value = true;
     
         var response = await atualizaOrcamento(postData);
        /* console.log("response mudança editar", response) */
        return response;
    }

    return { 
        atualiza,
        save,   
        dominio_orcamento,
        loadingButton 
    };
  },
};
